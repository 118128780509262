import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools'
import { Container, Row, Col } from 'reactstrap';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import Hero from 'components/Hero';
import Section from 'components/Section';
import supportHero from 'images/hero/image-support-hero.png';
import { useLayoutContext } from 'context/LayoutContext';
import SupportCard from 'components/SupportCard';
import SEO from 'components/SEO';

const SupportWrapper = styled(SectionsWrapper)`
  background-color: ${prop('theme.colors.lighterGray')};
`;

const StyledRow = styled(Row)`
  padding-bottom: 1rem;
  padding-top: 3rem;

  .col-sm-12 {
    padding-bottom: 2rem;
  }
`;

function SupportPage({ showPromoBar }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO
        title='Support'
      />
      <Hero
        heroImage={supportHero}
        $showAlert={showAlert}
        showPromoBar={showPromoBar}
      >
        <h1>Support</h1>
        <h2>Freedom to trade skillfully</h2>
      </Hero>
      <SupportWrapper>
        <Section>
          <Container>
            <StyledRow>
              <Col sm={12} md={6} lg={4}>
                <SupportCard
                  title="Account Opening & Management"
                  listItems={[
                    'Account Opening',
                    'Account Management',
                    'Trading Levels & Suitability',
                    'International Accounts',
                    'Retirement Accounts (IRAs)',
                  ]}
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SupportCard
                  title="Getting Started"
                  listItems={[
                    'Intro to tastytrade',
                    'Platform Quick Guides',
                    'Platform Overviews',
                  ]}
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SupportCard
                  title="Software, Platforms, & Tech Support"
                  listItems={[
                    'Features & Navigation',
                    'Order Entry',
                    'Platform Numbers & Calculations',
                    'Tech Support',
                  ]}
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SupportCard
                  title="Deposits, Withdrawls, & Transfers"
                  listItems={[
                    'ACH Deposits & Withdrawls',
                    'Brokerage Transfers (ACATS)',
                    'Internal Transfers',
                    'Wire Transfers',
                    'IRA Contributions/Distributions',
                  ]}
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SupportCard
                  title="Trading, Buying Power, & Margin"
                  listItems={[
                    'BP Effect / Margin Requirements',
                    'Margin Calls & Violations',
                    'Expiration, Exercise, & Assignment',
                    'Position Management',
                    'Trading',
                  ]}
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SupportCard
                  title="Tax Center"
                  listItems={[
                    'Tax Form Guides',
                    'Tax Center & Documents',
                    'IRAs',
                    'Data Imports/Exports',
                    'Tax Treatment & Rules',
                  ]}
                />
              </Col>
            </StyledRow>
          </Container>
        </Section>
      </SupportWrapper>
    </Layout>
  );
}

export default SupportPage;
