import React from 'react';
import styled from 'styled-components';
import { Card, CardHeader, CardFooter, CardBody } from 'reactstrap';

const StyledSupportCard = styled(Card)`
  background-color: ${props => props.theme.colors.white};
  margin: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 21.25rem;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  text-transform: uppercase;
  font-weight: 700;

  &.card-header {
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    height: 5rem;

    @media (min-width: ${props => props.theme.breakpoints.lg})
    and (max-width: ${props => props.theme.breakpoints.xl}) {
      height: 7rem;
    }
`;

const StyledCardBody = styled(CardBody)`
  min-height: 14.75rem;

  .support-li {
    padding-left: 1.875rem;
    line-height: 2.25;

    li {
      a {
        text-decoration: none;

        &:hover {
          color: ${props => props.theme.colors.primaryRed};
        }
      }
    }
  }
`;

const StyledCardFooter = styled(CardFooter)`
  a.float-right {
    color: ${props => props.theme.colors.primaryRed};
    text-decoration: none;
  }
`;

const SupportCard = ({ title, listItems }) => (
  <StyledSupportCard>
    <StyledCardHeader tag="h4">{title}</StyledCardHeader>
    <StyledCardBody>
      <ul className="support-li">
        {listItems.map((item, index) => (
          <li key={`support_link_${index}`}>
            <a href="#">{item}</a>
          </li>
        ))}
      </ul>
    </StyledCardBody>
    <StyledCardFooter>
      <a href="#" className="float-right">
        view all ({listItems.length})
      </a>
    </StyledCardFooter>
  </StyledSupportCard>
);

export default SupportCard;
